<!--能人详情-->
<template>
    <div class="bestPerson">
        <div class="breadcrumb w-1400">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/business'}">乡宁旺</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/enterprise'}">企业列表</el-breadcrumb-item>
                <el-breadcrumb-item >企业详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="e-h2 w-1400">
            {{pageData.name}}
        </div>
        <div class="p-list w-1400">
            <div class="floor1 clearfix">
                <div class="f1-pic fl"><!-- 此处应显示多图!!! -->
                    <el-carousel height="500px" arrow="never">
                        <el-carousel-item v-for="item in pageData.photoUrls" :key="item">
                            <el-image style="width:100%;height:100%" fit="cover" :src="item"></el-image>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="foorl1 fl">
                    <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 基本信息</div>
                    <div class="floor-text f1-r" v-html="pageData.introduction"></div>
                </div>
            </div>
            <div class="floor2 mt-10">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 发展历程</div>  
                <div class="floor-text licheng"  >
                    {{pageData.history}}
                    
                </div>  
            </div> 
            <div class="floor3 mt-10">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 企业文化</div>  
                <div class="floor-text ">{{pageData.culture}}</div>  
            </div> 
            <div class="floor4 mt-10">
                <div class="floor-h3"><i class="iconfont icon-fuzhushuxian"></i> 企业荣誉</div>  
                
                <el-row :gutter="30">
                    <el-col :span="6" v-for="item in pageDate.list" :key="item.id">
                        <div class="f4-pic">
                            <!-- <el-image style="width:100%;heihgt:100%" fit="cover" :src="require('../../../../public/img/xnw/ry1.png')"></el-image>  -->
                                <el-image :src="baseUrl+item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>   
                        </div>
                        <div class="f4-title ellipsis">{{item.name}}</div>
                    </el-col>
                </el-row> 
                 
            </div>
            <div class="pageTo">
                <p @click="prev" :class="{ dis: prevDis }">
                    上一篇：{{ prevRow.name || '已经是第一篇了' }}
                </p>
                <p @click="next" :class="{ dis: nextDis }">
                    下一篇：{{ nextRow.name || '已经是最后一篇了' }}
                </p>
            </div>
        </div> 
    </div>
        
</template>
<script>
import {GetOneEnterpriseInfos,GetEnterpriseHonor,GetEnterpriseInfos} from '@/api/xiangningwang'
export default {
    data(){
        return{
            pageData:{
                name:'',
            photoUrls:'',
            culture:'',
            history:''
            },
            pageDate:{},
            prevDis: false,
            nextDis: false,
            prevRow: {},
            nextRow: {},
        }
    },
    computed:{
        baseUrl:()=>process.env.VUE_APP_PHOTO_URL,
        regHtml:()=>regHtml,
        listParams: function () {
            return this.$route.query.oParams
                ? JSON.parse(this.$route.query.oParams)
                : {}
        },
    },
    methods:{
        getDetail(){
            GetOneEnterpriseInfos({id:this.$route.query.id}).then(res=>{
                const data = res.data.data;
                data.photoUrls = data.photoUrls.split(",");
                this.pageData = data;

                //企业荣誉
                const  params={
                    currentPage:1,
                    filter:{enterpriseId:res.data.data.id },
                    pageSize:8,
                }
                GetEnterpriseHonor(params).then(res=>{
                    if(res.data.data!=null){
                        this.pageDate=res.data.data;
                    }
                })
            })
        },
        prev() {
            if (this.prevRow.id) {
                this.$router.push({
                    path: '/redirect2',
                    query: {
                        path: this.$route.path,
                        id: this.prevRow.id,
                        type: this.$route.query.type,
                        oParams: this.prevParams
                        ? JSON.stringify(this.prevParams)
                        : JSON.stringify(this.listParams),
                    },
                })
            }
        },
        next() {
            if (this.nextRow.id) {
                this.$router.push({
                    path: '/redirect2',
                    query: {
                        path: this.$route.path,
                        id: this.nextRow.id,
                        type: this.$route.query.type,
                        oParams: this.nextParams
                        ? JSON.stringify(this.nextParams)
                        : JSON.stringify(this.listParams),
                    },
                })
            }
        },
        getList() {
            GetEnterpriseInfos(this.listParams).then((res) => {
                this.actIndex = res.data.data.list.findIndex((v) => v.id == this.$route.query.id) || 0
                this.pageTotal = res.data.data.total
                this.listData = res.data.data.list
                this.prevRow = this.listData[this.actIndex - 1] || {}
                this.nextRow = this.listData[this.actIndex + 1] || {}
                if (this.actIndex == 0) {
                    if (this.listParams.currentPage <= 1) {
                        this.prevDis = true
                    } else {
                        this.prevParams = { ...this.listParams }
                        this.prevParams.currentPage -= 1
                        GetEnterpriseInfos(this.prevParams).then((res) => {
                            this.prevRow = res.data.data.list[res.data.data.list.length - 1]
                        })
                    }
                }
                if (this.actIndex == this.listData.length - 1) {
                if (
                    (this.listParams.currentPage - 1) * this.listParams.pageSize +
                    this.actIndex +
                    1 >=
                    this.pageTotal
                ) {
                    this.nextDis = true
                } else {
                    this.nextParams = { ...this.listParams }
                    this.nextParams.currentPage += 1
                    GetEnterpriseInfos(this.nextParams).then((res) => {
                        this.nextRow = res.data.data.list[0]
                    })
                }
                }
            })
        },
        // getEnterHoner(){
        //     GetEnterpriseHonor(this.params).then(res=>{
        //         debugger;
        //         this.pageDate=res.data.data;
        //     })
        // }
     },
    mounted(){
        this.getDetail();
        if (this.$route.query.oParams) {
            this.getList()
        }
        //this.getEnterHoner();
    }
}
</script>
<style scoped>
.pageTo p:not(.dis):hover {
  color: #0e6ac1;
  text-decoration: underline;
  cursor: pointer;
}
.pageTo p.dis {
  color: #999;
}
.pageTo p {
  padding: 5px 0;
}
.pageTo {
  margin-top: 40px;
  font-size: 16px;
  border-top: 2px solid #e5e5e5;
}
.f4-title{
    font-size: 18px;
    text-align: center;
    line-height: 32px;
}
.f4-pic{
    /* width: 280px; */
    height: 190px;
    overflow:hidden;
}
.floor-text.licheng{
    padding-left: 30px;
    text-indent: 0px;
}
.foorl1{
    width: calc(100% - 600px);
    height: 500px;
    padding: 20px;
    overflow-y: auto;
}
.foorl1::-webkit-scrollbar {
    width:5px;
}
.foorl1::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    border-radius:10px;
    /* display: none; */
}
.foorl1::-webkit-scrollbar-thumb {
    border-radius:1px;
    background:#709df1;
    /* -webkit-box-shadow:inset006pxrgba(0,0,0,0.5); */
}
.floor-text{
    font-size: 16px;
    line-height: 30px;
    text-indent: 30px;
}
.f1-pic{
    width: 600px;
    height: 500px;
    overflow: hidden;
}
.f1-pic >>> .el-carousel__button{
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.e-h2{
    font-size: 30px;
    font-weight: 60px;
}
.floor-h3 i{
    font-size: 22px;
}
.floor-h3{
    line-height: 42px;
    font-size: 22px;
    color: #0e6ac1;
}
.bestPerson{
    font-family: '微软雅黑';
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>